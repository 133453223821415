import Vue from 'vue'
import VueRouter from 'vue-router'
import BaseLayer from '@/components/layout/BaseLayer.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        component: BaseLayer,
        children: [{
            path: '',
            name: 'MainPage',
            component: () => import('@/views/Main.vue'),
            meta: {title: '评估演示'}
        }, {
            path: 'detail',
            name: 'EvalDetail',
            component: () => import('@/views/EvalDetail.vue'),
            meta: {title: '评估结果查看'}
        }]
    },
    {
        path: '*',
        name: '404',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('@/views/404.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
