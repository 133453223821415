import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './icons'
Vue.config.productionTip = false
require('./vant-import.js')
require('@/styles/demo.scss')
router.beforeEach(async (to, from, next) => {
    document.title = to.meta.title || '演示'
    next()
})

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
