<template>
  <div class="app-layer">
    <router-view/>
  </div>
</template>
<script>
export default {
  name: 'BaseLayer',
  data() {
    return {}
  },
  mounted() {
  },
  methods: {}
}
</script>

<style scoped lang="scss">

</style>