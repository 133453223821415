import Vue from 'vue'

import('vant/lib/index.css')
import {
    Button,
    Dialog,
    NavBar,
    Form,
    Field,
    Picker,
    Popup,
    Cascader,
    Notify,
    Search,
    Stepper,
    List,
    Cell,
    CellGroup,
    Tab,
    Tabs,
    Sticky,
    Empty,
    Circle,
    Image as VanImage,
    NoticeBar,
    Divider,
    Icon,
    Radio,
    RadioGroup
} from "vant";


Vue.use(Button)
Vue.use(Dialog)
Vue.use(NavBar)
Vue.use(Form)
Vue.use(Field)
Vue.use(Picker)
Vue.use(Popup)
Vue.use(Cascader)
Vue.use(Notify)
Vue.use(Search)
Vue.use(List)
Vue.use(Cell)
Vue.use(CellGroup)
Vue.use(Stepper)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(Sticky)
Vue.use(Empty)
Vue.use(Circle)
Vue.use(VanImage)
Vue.use(NoticeBar)
Vue.use(Divider)
Vue.use(Icon)
Vue.use(RadioGroup)
Vue.use(Radio)
